import React, {
    useEffect,
    useState
} from "react";
import moment from "moment";

import MerryChristmasScreen from "../screens/MerryChristmasScreen";
import PlanningScreen from "../screens/PlanningScreen";
import RotatingScreen from "../screens/RotatingScreen";
import DevelopLinksScreen from "../screens/DevelopLinksScreen";

function AutoDisplaySwitching() {
    const [today, setToday] = useState(null);
    const [screen, setScreen] = useState(null);

    useEffect(() => {
        const callback = () => {
            const urlParams = new URLSearchParams(window.location.search);
            setScreen(urlParams.get("screen"));
            setToday(moment());
        };
        const interval = setInterval(callback, 60 * 1000);
        callback();

        return () => clearInterval(interval);
    }, []);

    if(!screen) {
        return null;
    }

    const day = today.format("MM-DD");
    if(((day === "12-22" || day === "12-23") && today.hour() > 18) || (day === "12-24" && today.hour() < 8)) {
        if(screen === "bottom") {
            return null;
        }
        return (
            <MerryChristmasScreen/>
        );
    }
    switch(screen) {
        case "monteur":
        case "kantine-links":
        case "service":
            return (
                <PlanningScreen/>
            );
        case "kantine-midden":
            return (
                <RotatingScreen/>
            );
        case "develop-links":
            return (
                <DevelopLinksScreen/>
            );
        default:
            return (
                <div className="w-100 text-center text-white">
                    <h1>Invalid screen</h1>
                </div>
            );
    }
}

export default React.memo(AutoDisplaySwitching);
